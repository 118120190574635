import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";

import TouchlessFlatware from "../../components/TouchlessFlatware";

const TouchlessFlatwarePage = ({ data }) => (
  <Layout>
    <TouchlessFlatware data={data} />
  </Layout>
);

export default TouchlessFlatwarePage;

export const query = graphql`
  query {
    shark: file(relativePath: { eq: "mj-sharks.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
